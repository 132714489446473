import React, { useState } from 'react';
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import numeral from 'numeral';
import moment from 'moment';

import Mod from '../components/Modal';

export default (props) => {

    const firebase = React.useContext(FirebaseContext)

    const id = props.location.search.split('=').pop();

    const [artist,setartist] = useState({})
    const [product,setproduct] = useState({})
    const [images,setimages] = useState([])

    const [open,setopen] = useState(false)

    const [name,setname] = useState('')
    const [phone,setphone] = useState('')
    const [email,setemail] = useState('')
    
    useFirebase(firebase => {
        if (id) {
            firebase.firestore().collection('products').doc(id).get().then(snap => {
                setproduct(snap.data())
            })
            firebase.firestore().collection('images').where('productId','==',id).get().then(snap => {
                const data = snap.docs.map(doc => doc.data())
                setimages(data)
            })
        }
    },[id])

    useFirebase(firebase => {
        if (product.brandId) {
            firebase.firestore().collection('brands').doc(product.brandId).get().then(snap => {
                setartist(snap.data())
            })
        }
    },[product.brandId])

    return (
        <Layout>
            <div css={css`
                background-color: #fff;
                height: 100%;
                padding: 4.5rem 2.5rem 4.5rem 19rem;
                display: flex;
                @media only screen and (max-width: 27em) {
                    flex-direction: column-reverse;
                    padding-left: 2.5rem;
                }
            `}>
                <div css={css`
                    position: relative;
                    flex: 3;
                    margin-right: 2.5rem;
                    @media only screen and (max-width: 27em) {
                        flex: unset;
                        margin-right: 0;
                        margin-top: 2.5rem;
                    }
                `}>
                    {
                        images.map(item => <picture css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 2.5rem;
                        `}>
                            <img css={css`
                                width: 95%;
                                max-width: 60rem;
                            `} src={item.file} alt={item.id} />
                        </picture>)
                    }
                </div>
                <div css={css`
                    flex: 2;
                    position: relative;
                    @media only screen and (max-width: 27em) {
                        flex: unset;
                    }
                `}>
                    <div css={css`
                        position: sticky;
                        top: 2.5rem;
                        display: flex;
                        flex-direction: column;
                    `}>
                        <h3 css={css`
                            font-size: 2rem;
                            color: var(--color-primary-dark);
                            @media only screen and (max-width: 27em) {
                                text-align: center;
                            }
                        `}>{artist.name}</h3>
                        <h1 css={css`
                            font-size: 3rem;
                            color: var(--color-primary);
                            @media only screen and (max-width: 27em) {
                                text-align: center;
                            }
                        `}>{product.name}</h1>
                        <p css={css`
                            margin-top: 2.5rem;
                            text-align: center;
                            font-size: 1.2rem;
                            color: var(--color-primary-dark);
                        `}>{product.description}</p>
                        <b css={css`
                            font-size: 2.5rem;
                            text-align: center;
                            color: var(--color-primary-dark);
                            margin-top: 2.5rem;
                        `}>{numeral(product.price).format('$0,0.00')} mxn</b>
                        <button onClick={() => setopen(true)} css={css`
                            margin-top: 4.5rem;
                            padding: 1rem 2rem;
                            font-size: 1.4rem;
                            background-color: transparent;
                            border: 2px solid var(--color-primary-dark);
                            cursor: pointer;
                            color: var(--color-primary-dark);
                            text-transform: uppercase;
                            transition: all .5s;
                            outline: none;
                            :hover {
                                background-color: var(--color-primary-dark);
                                color: #fff;
                            }
                        `}>Request information</button>
                        <button css={css`
                            margin-top: 2.5rem;
                            padding: 1rem 2rem;
                            font-size: 1.4rem;
                            background-color: var(--color-primary-dark);
                            border: none;
                            cursor: pointer;
                            color: #fff;
                            outline: none;
                            text-transform: uppercase;
                        `}>Buy now</button>
                    </div>
                </div>
            </div>

            <Mod isOpen={open} closeModal={() => setopen(false)} title="Solicitud de información">
                <div css={css`
                    padding: 2rem;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    .item {
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        font-size: 1.6rem;
                        label {
                            padding: .5rem;
                        }
                        input {
                            padding: 1rem;
                            margin-top: .5rem;
                            outline: none;
                        }
                    }
                `}>
                    <h4 css={css`
                        font-size: 2rem;
                        text-align: center;
                        color: var(--color-grey-dark-1);
                    `}>Envíanos tus datos, para recibir más información</h4>
                    <div className="item">
                        <label htmlFor="name">Name</label>
                        <input value={name} onChange={e => setname(e.target.value)} id="name" placeholder="Name" type="text"/>
                    </div>
                    <div className="item">
                        <label htmlFor="phone">Phone</label>
                        <input value={phone} onChange={e => setphone(e.target.value)} id="phone" placeholder="Phone" type="text"/>
                    </div>
                    <div className="item">
                        <label htmlFor="email">Email</label>
                        <input value={email} onChange={e => setemail(e.target.value)} id="email" placeholder="Email" type="text"/>
                    </div>
                    <div className="item">
                        <button onClick={handleSubmit} css={css`
                            padding: 1rem 2rem;
                            font-size: 1.4rem;
                            background-color: var(--color-primary-dark);
                            border: none;
                            cursor: pointer;
                            color: #fff;
                            outline: none;
                            text-transform: uppercase;
                            width: 100%;
                        `}>Send</button>
                    </div>
                </div>
            </Mod>
        </Layout>
    )

    async function handleSubmit () {
        try {
            if (name && email && phone) {
                var item = {
                    name,
                    email,
                    phone,
                    timestamp: moment().valueOf(),
                    productId: id
                }
                const ref = firebase.firestore().collection('info-request').doc()
                item.id = ref.id
                ref.set(item).then(() => {
                    const emailRef = firebase.firestore().collection('emails').doc()
                    item.message = `
                        <div style="max-width:600px; width:100%;">
                            <p>Alguien solicitó información de una obra de la galería.</p>
                            <ul>
                                <li><b>Producto: <a href="https://columnamx.com/producto?id=${id}">${product.name}</a></li>
                                <li><b>Nombre de Cliente: </b> ${name}</li>
                                <li><b>Email de Cliente: </b> ${email}</li>
                                <li><b>Teléfono de Cliente: </b> ${phone}</li>
                            </ul>
                            <p>Gracias,</p>
                            <p>Columna Mx.</p>
                        </div>
                    `
                    item.email = 'alejandro@columnamx.com, info@columnamx.com, sam@columnamx.com'
                    // item.email = 'ebernal.n98@gmail.com'
                    item.subject = 'Solicitud de información de producto de Galería'
                    item.text = 'Solicitud de información de producto de Galería'
                    emailRef.set(item).then(() => {
                        setname('')
                        setemail('')
                        setphone('')
                        alert('Un asesor se pondrá en contacto contigo')
                        setopen(false)
                    })
                })
            } else {
                alert('Escribe un nombre, email y teléfono para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}